import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='bg-black mt-5 text-white'>
      <div className='container pt-5'>
        <div className='row pt-5 pb-4'>
          <div className='col-6'>
            <div class="card w-100 bg-black text-white border border-secondary">
              <div class="card-body row">
                <div className='col-2'>
                <FontAwesomeIcon icon={faEnvelope} size="3x" />
                </div>
                <div className='col-10'>
                  <p class="card-title">Write to us</p>
                  <p class="card-text">syed.sherazi@ecm-cs.com.au</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-5 pb-5'>
          <div className='col-6'>
            <h3>About ECM CS</h3>
            <p>ECM CS is a client centric engineering firm that specializes in all sectors of the power and utility industry.</p>
          </div>
          <div className='col-6'>
            <div className='row'>
              <div className="col-12">
                <h3>Quick Links</h3>
                <div className='row'>
                  <ul className="list-unstyled col-6">
                    <li><a href="/" className="px-3 nav-link" target="_blank">Home</a></li>
                    <li><a href="/services" className="px-3 nav-link" target="_blank">Services</a></li>
                  </ul>
                  <ul className="list-unstyled col-6">
                    <li><a href="/services" className="px-3 nav-link" target="_blank">Services</a></li>
                    <li><a href="/contact-us" className="px-3 nav-link" target="_blank">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
