import React from 'react'
import AboutSummary from "./AboutSummary";
import Services from "./Services";
import OurWork from "./OurWork";

const Home = () => {
  return (
    <div>
      <AboutSummary />
      <Services />
      <OurWork />
    </div>
  )
}

export default Home