import React from 'react';
import { ReactTyped } from "react-typed";

const Hero = ({ textProp }) => {
  const textMap = {
    home: {
      title: 'Welcome to ECM CS.',
      subtitle: 'Guaranteed High Quality',
      typedStrings: ["Engineers", "Inventors", "Masterminds"],
    },
    aboutUs: {
      title: 'About Us Title',
      subtitle: 'About Us Subtitle',
      typedStrings: ["Item 1", "Item 2", "Item 3"],
    },
  };

  const { title, subtitle, typedStrings } = textMap[textProp] || textMap.home;

  return (
    <div className='text-white'>
      <div className='container vh-100 d-flex justify-content-center align-items-center'>
        <div className='text-center'>
          <p className='text-uppercase'>{subtitle}</p>
          <h1 className='display-1 fw-bold py-3'>{title}</h1>
          <div className='d-flex justify-content-center h3'>
            <p className='fw-bold'>Fast, flexible planning from</p>
            <ReactTyped
              className='fw-bold text-success ps-2'
              strings={typedStrings}
              typeSpeed={100}
              backSpeed={100}
              loop
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
