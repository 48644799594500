import React from 'react';
import Hero from './Hero';

const Main = () => {
  return (
    <div className="backgroundImageContainer vh-100">
      <Hero textProp="home" />
    </div>
  )
}

export default Main;