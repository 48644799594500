import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qsvvw0e', 'template_r4h5kax', form.current, { publicKey: 'mobB8NI7NbzP5F8JD' })
      .then(
        () => {
          console.log('SUCCESS!');
          form.current.reset(); // Clear the form fields
          setShowSuccessMessage(true); // Show success message
          setTimeout(() => setShowSuccessMessage(false), 5000); // Hide the success message after 5 seconds
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className='mt-5'>
      <Container>
        <Row>
          <Col md={6} className="p-3">
            <h3>Get in touch</h3>
            {showSuccessMessage && <Alert variant="success">Your message has been sent successfully!</Alert>}
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group className="mb-3">
                <Form.Label>Your Name *</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" required name="name" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your Email *</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" required name="email" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Your Contact Number *</Form.Label>
                <Form.Control type="tel" placeholder="Enter your phone number" name="contact_number" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Message *</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Your message" required name="message" />
              </Form.Group>

              <Button variant="primary" type="submit">
                Send Message
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactUs;