const servicesData = {
  distributionEngineering: {
    title: "Distribution Engineering",
    description: "We as a company continue to evolve to meet changing demands and incorporate new technologies for a more sustainable and resilient grid. Our team of engineers produce the highest quality of safe and reliable designs while ensuring the stability and effectiveness of the electrical distribution system.",
    points: [
      "Distribution System Planning: Our Engineers assess the existing distribution network and plan for its expansion or optimization to meet current and future energy demands. This includes: load analysis, system reliability studies, and capacity planning.",
      "Design and Engineering: Detailed engineering designs are created by our experts for distribution substations, transformers, switchgear, and distribution lines.",
      "Renewable Integration: With the increasing adoption of renewable energy sources like solar and wind, distribution engineers work on integrating these sources into the grid while maintaining system stability.",
      "Grid Modernization: Engineers may upgrade and modernize aging distribution infrastructure to incorporate smart grid technologies, such as advanced metering, automation, and control systems.",
      "Reliability Improvement: The focus is on reducing outage durations and improving system reliability. This may involve fault analysis and the installation of protective devices.",
      "Energy Efficiency: Distribution engineers may implement measures to enhance energy efficiency, reducing energy losses and environmental impact.",
      "Voltage Management: Maintaining the appropriate voltage levels throughout the distribution network is essential for safe and efficient power delivery.",
      "Safety and Regulatory Compliance: Ensuring that the distribution system meets safety standards and complies with regulatory requirements is a critical aspect of our services."
    ]
  },
  substationEngineering: {
    title: "Substation Engineering",
    description: "As substation engineering is pivotal to the functioning of the electrical grid, our team ensures that substation control designs are created in a manner that optimizes operation management of the substations while ensuring safety, reliability, and compliance with industry standards and regulations.",
    points: [
      "Substation Design: Our Engineers design substations to meet specific electrical requirements.",
      "Site Selection and Feasibility Studies: Assessing potential locations for substations and conducting feasibility studies to evaluate their suitability based on factors like land availability, environmental impact, and proximity to load centres.",
      "Equipment Specification: Through their vast knowledge our engineers select and specify the appropriate transformers, switchgear, circuit breakers, and other substation equipment based on electrical load and voltage requirements.",
      "Protection and Control Systems: Designing protective relay systems and control systems to ensure the safe operation of the substation and prevent electrical faults.",
      "Substation Automation: Integrating automation and control systems to enable remote monitoring, control, and diagnostics, enhancing the efficiency and reliability of the substation.",
      "Environmental Impact Assessment: Conducting assessments to minimize the environmental impact of the substation and comply with regulatory requirements.",
      "Grid Integration: Ensuring seamless integration of the substation into the existing electrical grid, including connecting transmission and distribution systems."
    ]
  },
  transmissionEngineering: {
    title: "Transmission Engineering",
    description: "We provide end-to-end solutions for transmission line projects, ensuring the safe and reliable transmission of electricity by designing overhead and underground high voltage transmission lines over long distances.",
    points: [
      "Transmission Line Design: Our Engineers design high-voltage transmission lines that transport electricity from power generation sources to substations.",
      "Substation Design: Designing transmission substations that connect multiple transmission lines, transform voltages, and distribute power to lower-voltage distribution networks.",
      "System Planning and Expansion: Analyzing the existing transmission network and planning for its expansion to accommodate increasing demand or integrate renewable energy sources.",
      "Load Flow and Stability Studies: Conducting load flow analysis and stability studies to ensure that the transmission system can handle varying loads and maintain stability during contingencies.",
      "Reliability Enhancement: Identifying and addressing vulnerabilities in the transmission system to minimize the risk of outages and disruptions.",
      "Grid Integration: Integrating new transmission lines into the existing grid, including connection to substations and coordination with distribution systems.",
      "HVDC Transmission: Designing and implementing high-voltage direct current (HVDC) transmission systems for long-distance power transmission.",
      "Maintenance and Asset Management: Developing maintenance strategies to ensure the reliability and longevity of transmission equipment.",
      "Grid Automation: Implementing automation and control systems to monitor, manage, and control the transmission network in real-time.",
      "Safety Compliance: Ensuring compliance with safety standards and regulations for personnel working on and around transmission infrastructure.",
      "Testing and Commissioning: Conducting comprehensive testing and commissioning of new transmission lines and substations to verify their proper operation and reliability."
    ]
  },
  projectManagement: {
    title: "Project Management & Project Controls",
    description: "Welcome to our Project Management and Project Controls Services page. We specialize in providing comprehensive project management and project controls solutions for power utility projects. Explore our range of services below.",
    points: [
      "Effective Management: Our project management services cover all aspects of your power utility projects. From project initiation to completion, we provide project planning, scheduling, and coordination to ensure project success. Our team manages scope, budgets, resources, and timelines, while keeping stakeholders informed and engaged throughout the project lifecycle.",
      "Project Planning and Risk Management: We develop detailed project plans that outline the project scope, objectives, deliverables, and milestones. Our team identifies and mitigates potential risks and develops risk management strategies to keep your power utility projects on track. We also perform regular risk assessments and adjustments to ensure project success.",
      "Project Controls and Performance Monitoring: Our project controls services include cost control, schedule management, and performance monitoring. We establish robust project control systems to track project progress, identify deviations, and implement corrective actions. Through comprehensive reporting and analysis, we provide real-time visibility into project performance and ensure adherence to budgets and schedules.",
      "Stakeholder Management: We understand the importance of effective stakeholder management in power utility projects. Our team engages with stakeholders, including clients, regulatory bodies, suppliers, and contractors, to foster positive relationships and ensure alignment with project goals. We facilitate communication, manage expectations, and address any concerns or issues that may arise during the project.",
      "Quality Assurance and Control: We implement stringent quality assurance and control measures throughout your power utility projects. Our team develops and implements quality management plans, conducts inspections, and ensures compliance with industry standards and regulations. We focus on delivering projects that meet the highest quality standards and exceed client expectations.",
      "Contract Administration and Vendor Management: Our services include contract administration and vendor management for power utility projects. We manage contracts, negotiate terms and conditions, and ensure adherence to contractual obligations. We also oversee vendor selection, performance evaluation, and contract closure to maximize value and minimize risks associated with external suppliers and contractors."
    ]
  },
  ownersEngineering: {
    title: "Power Utility Owners Engineering Services",
    description: "Welcome to our Owners Engineering Services page. Explore our range of services below:",
    points: [
      "Feasibility Studies and Conceptual Design: We conduct thorough feasibility studies to assess the viability of your power utility projects. Our team evaluates technical, economic, and regulatory factors to provide you with valuable insights for informed decision-making. We also assist in developing conceptual designs that align with your goals and project requirements.",
      "Project Planning and Permitting: Our team assists in project planning and permitting, ensuring compliance with relevant regulations and obtaining necessary permits. We collaborate with you to develop comprehensive project plans, including resource allocation, scheduling, and coordination with stakeholders. Our experts navigate the permitting process to facilitate a smooth project initiation.",
      "Engineering Design and Specifications: We provide engineering design services tailored to your power utility projects. Our team develops detailed design plans and specifications for power generation, transmission, and distribution systems. We focus on safety, efficiency, and sustainability, considering factors such as equipment selection, system integration, and compliance with industry standards.",
      "Procurement and Contract Management: We support you in procurement and contract management activities. Our experts assist in vendor selection, preparation of procurement documents, and contract negotiations. We ensure that contracts align with project objectives and help you effectively manage supplier relationships to optimize project outcomes.",
      "Construction Oversight and Quality Assurance: Our team offers construction oversight and quality assurance services to ensure adherence to design specifications and industry standards. We conduct site inspections, monitor progress, and address any construction-related issues that may arise. Our focus is on delivering high-quality construction that meets your expectations.",
      "Testing, Commissioning, and Start-up Support: We provide comprehensive support during the testing, commissioning, and start-up phases of your power utility projects. Our team develops testing protocols, supervises commissioning activities, and ensures a smooth transition to operational status. We work closely with you to verify system performance and functionality.",
      "Performance Monitoring and Optimization: Our services include ongoing performance monitoring and optimization of your power utility assets. We conduct regular inspections, analyze operational data, and identify opportunities for performance improvement. Our experts provide recommendations for optimization, maintenance planning, and system upgrades to enhance overall efficiency and reliability."
    ]
  },
  fieldServices: {
    title: "Field Services",
    description: "DST Engineers offers all-inclusive plotting on surveys, correct procedures, and mapping services. Technologies and equipment used are up to date to ensure the deliverance is complete, and the accuracy of the data is the highest. From our congested urban highways surveys to rugged rural mapping, we efficaciously mitigate challenges by our routine practice to understand our customers' fears and tackle them. Right of Way, Land Acquisition and Survey Expertise. Our team, composed of highly skilled land and survey professionals, provides solutions to the most challenging problems with verified procedures, devoted representatives, and technologies of the state-of-the-art. Our methodology comprises setting objectives, agent stalking, a powerful development approach, and exemplary teamwork. Our integrated teams review data through a shared technology platform daily to ensure accuracy and maintain project schedules. Data integrity and transparency are central to our processes. Our field staff is highly credentialed and knowledgeable in providing quality data by using modern technology.",
    points: [
      "Data collection",
      "Surveying and Mapping",
      "Pole Surveys",
      "Post Inspection",
      "Pole Ownership",
      "Staking Services",
      "GPS Acquisition"
    ]
  }
};

export default servicesData;