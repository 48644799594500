import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function CustomNavbar() {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    let pageTitle = '';

    if (path === '/') {
      pageTitle = 'ECM CS | Home';
    } else if (path === '/about-us') {
      pageTitle = 'ECM CS | About Us';
    } else if (path === '/contact-us') {
      pageTitle = 'ECM CS | Contact Us';
    } else {
      pageTitle = 'ECM CS | Services';
    }

    document.title = pageTitle;
  }, [location]);

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <div className="container">
        <Navbar.Brand href="#">
          <img
            src={logo}
            width="auto"
            height="80"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end d-flex nav-fonts">
          <Nav className="ml-auto">
            <Link to="/" className="nav-link px-3">Home</Link>
            <Link to="/about-us" className="nav-link px-3">About Us</Link>
            <Link to="/services" className="nav-link px-3">Our Services</Link>
            <Link to="/contact-us" className="nav-link px-3">Contact Us</Link>
            {/* <Link to="/about-us" className="nav-link">Careers</Link> */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default CustomNavbar;
