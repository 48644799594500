import React from 'react'
import { Link } from "react-router-dom";

const ServiceCard = ({img, title, route}) => {
  return (
    <div className='col-4'>
      <div className="card">
        <div className="zoom">
          <img src={img} alt="Card image cap" height={250} />
        </div>
        <div className="card-bod px-5 pt-4 pb-5">
          <p className="card-text sub-headings">{title}</p>
          <p className="card-text title service-card-link"><Link to={route} className="nav-link">View Service</Link></p>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard