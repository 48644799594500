import React, { useEffect, useState, useRef } from 'react';
import aboutUsImage from "../assets/logo.png";

const AboutSummary = () => {
  const [isVisible, setIsVisible] = useState(false);
  const aboutRef = useRef(null);

  // Function to handle scroll event
  const handleScroll = () => {
    if (aboutRef.current) {
      const top = aboutRef.current.getBoundingClientRect().top;
      const bottom = aboutRef.current.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      if (top < windowHeight && bottom >= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='container mx-auto row mt-5'>
      <div className={`col-6 px-0 ${isVisible ? 'fade-in-down' : 'fade-in-up'}`} ref={aboutRef}>
        <h2 className='display-4 heading-fonts'>About <span className='logo-text-color'>ECM</span></h2>
        <p className={`text-muted mt-4 ${isVisible ? 'fade-in-up' : 'fade-in-down'}`}>
          ECM is a comprehensive engineering consulting firm dedicated to delivering effective and innovative solutions for utility companies across Australia. Leveraging years of direct utility industry experience alongside a team of experienced consulting engineers, managers, and field specialists, we tackle client obstacles, bolstering their core strengths, improving performance, and ensuring the highest efficiency in program execution. Our approach enables clients to grasp intricate issues and transform them into highly productive opportunities that significantly contribute to organizational value and expansion.
        </p>
      </div>
      <div className='col-6 px-0 d-flex justify-content-center'>
        <img
          src={aboutUsImage}
          alt="About Us"
          width="auto"
          height="300"
        />
      </div>
    </div>
  );
}

export default AboutSummary;
