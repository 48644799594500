import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import servicesData from '../ServicesData';

function ServicesDetails({ type }) {
  function contactButtonHover(e) {
    e.currentTarget.style.backgroundColor = '#0994da';
    e.currentTarget.style.color = 'white';
  }

  function contactButtonOut(e) {
    e.currentTarget.style.backgroundColor = 'transparent';
    e.currentTarget.style.color = 'black';
  }


  return (
    <div className="container mt-5">
      <h1 className='mt-5 mb-4 heading-fonts'>{servicesData[type].title}</h1>
      <Link to="/contact-us" style={{ color: 'inherit', textDecoration: 'none' }}>
        <Button
          style={{
            backgroundColor: 'transparent',
            borderColor: '#ccc',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '5px',
            color: '#000',
            padding: '10px 20px',
            textDecoration: 'none'
          }}
          className="mb-5"
          onMouseOver={(e) => contactButtonHover(e) }
          onMouseOut={(e) => contactButtonOut(e) }
        >
          Contact Us
        </Button>
      </Link>
      <p className='normal-text-fonts fw-light'>{servicesData[type].description}</p>
      <div>
        <ol>
          {servicesData[type].points.map((point, index) => (
            <li key={index} className='py-2'><strong>{point.split(': ')[0]}:</strong>{point.split(': ')[1]}</li>
          ))}
        </ol>
      </div>
    </div>
  );
}

export default ServicesDetails;