import React from 'react'
import ServiceCard from './ServiceCard'
import distEng from "../assets/dist-eng.jpeg";
import subEng from "../assets/sub-eng.jpeg";
import tranEng from "../assets/trans-eng.jpeg";
import project from "../assets/project-man.jpeg"
import owners from "../assets/owners-eng.jpeg"
import field from "../assets/field-ser.jpeg"


const Services = () => {
  return (
    <div className='bg-ligh pt-5'>
      <div className='container mx-auto mt-5'>
        <h2 className='display-5 heading-fonts'>Our <span className='logo-text-color'>Services</span></h2>
        <div className='row mt-3'>
          <ServiceCard img={distEng} title='Distribution Engineering' route='/distribution-engineering' />
          <ServiceCard img={subEng} title='Substation Engineering' route='/substation-engineering' />
          <ServiceCard img={tranEng} title='Transmission Engineering' route='/transmission-engineering' />
        </div>
        <div className='row mt-5'>
          <ServiceCard img={project} title='Project Management' route='/project-management' />
          <ServiceCard img={owners} title='Owners Engineering' route="/owners-engineering" />
          <ServiceCard img={field} title='Field Services' route="/field-services" />
        </div>
      </div>
    </div>
  )
}

export default Services