import React from 'react';

const OurWork = () => {
  return (
    <div>
      <div className="our-work-background-img vh-100 mt-5 text-white d-flex flex-column justify-content-center align-items-center">
        <div className='w-50'>
          <h2 className='display-5 text-center'>Our Work</h2>
          <p className="text-center">Our aim is not just providing exceptional services; it’s more about creating a positive impact in the world through our expertise. Moreover, we are dedicated to providing exceptional client service which motivates every decision we make. Join us in our journey to electrify a better tomorrow.</p>
        </div>
      </div>
    </div>
  );
};

export default OurWork;
