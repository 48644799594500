import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/CustomNavbar";
import Main from "./components/Main";
import AboutUs from "./components/AboutUs";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";
import ServicesDetails from "./components/ServicesDetails";


function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Main />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/distribution-engineering" element={<ServicesDetails type='distributionEngineering' />} />
          <Route path="/substation-engineering" element={<ServicesDetails type='substationEngineering' />} />
          <Route path="/transmission-engineering" element={<ServicesDetails type='transmissionEngineering' />} />
          <Route path="/project-management" element={<ServicesDetails type='projectManagement' />} />
          <Route path="/owners-engineering" element={<ServicesDetails type='ownersEngineering' />} />
          <Route path="/field-services" element={<ServicesDetails type='fieldServices' />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
